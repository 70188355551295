@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Prata", serif;
  accent-color: var(--light-grey);
}


html{
  scroll-behavior: smooth;
}

body {
  height: 100svh;
  background: var(--white2);
}
