.header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-direction: row;
    list-style-type: none;
    place-content: center;
    background-color: transparent;
    height: 90px;
}

.header.scroll {
    background-color: var(--background-white);
}

li {
    place-content: center;
}
.header__logo {
    display: flex;
    align-items: center;
    height: auto;
}

.header__logo img {
    margin-top: 50px;
    position: absolute;
    left: 50%;
    top: 0;
    padding-top: 10px;
    transform: translateX(-50%);
    width: 900px;
    will-change: width, margin-top;
    transition: width 0.5s ease-in-out, margin-top 0.5s ease-in-out;
}

.header__logo img.scroll {
    margin-top: 0;
    width: 200px;
}

.header__logo img.scroll ~ .header__menu{
    color: var(--black);
}

.header button {
    background-color: transparent;
    border: none;
    text-decoration: none;
    color: var(--background-white);
    display: flex;
    place-content: center;
}

button:hover {
    color: var(--text-white);
    cursor: pointer;
}

button:active {
    color: var(--hover-text);
}

.header__menu {
    margin-left: auto;
    padding: 20px;
    font-size: 1.5em;
    z-index: 10;
}

.header__menu_black{
    margin-left: auto;
    padding: 20px;
    font-size: 1.5em;
    z-index: 10;
}

.header__menu_black button{
    color: var(--black);
}

.navMenu{
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    height: 100svh;
    width: 100svw;
    z-index: 3;
    place-content: flex-end;
    background-color: rgba(0,0,0,0.7);
    animation: fading 0.3s ease-in-out;
}

.navigation{
    background-color: var(--background-white);
    height: 100svh;
    width: 25svw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navigation li{
    list-style-type: none;
    color: var(--black);
    cursor: pointer;
    padding: 40px;
    width: 100%;
    font-size: 1.5em;
    font-weight: bold;
    display: flex;
    text-align: center;
}

.navigation img{
    width: 300px;
    object-fit: cover;
    padding: 30px;
}


@media (max-width: 850px) {
    .header{
        height: auto;
    }

    .header__menu {
        display: flex;
        margin-left: auto;
        width: min-content;
    }

    .header__logo img {
        top: 50%;
        width: 300px;
        transition: width 0.5s ease-in-out;
        margin: 0;
    }

    .header__logo img.scroll {
        top: 0;
        width: 150px;
    }

    .navigation{
        width: 100svw;
        height: 100vh;
    }

    .navigation li{
        font-size: 1em;
        place-content: flex-start;
        padding: 30px;
    }

}

@media (hover: none) {
    button:focus-visible {
        color: var(--hover-text);
    }
}

@keyframes fading {
    0%{
        background-color: rgba(0,0,0,0);
    }
    100%{
        background-color: rgba(0,0,0,0.7);
    }
}
