main {
    display: flex;
    flex-direction: column;
    place-content: center;
    width: 100%;
}

.about-us {
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100%;
    place-content: center;
    place-items: center;
    background-color: var(--white2);
}

.about-us .first-block {
    display: flex;
    text-align: center;
    font-size: 2em;
    padding: 80px;
}

.about-us .first-block p {
    padding: 40px;
    border-top: 3px solid var(--black);
    border-bottom: 3px solid var(--black);
}

.about-us .second-block {
    display: flex;
    justify-content: space-between;
    background-color: var(--background-white);
}

.about-us .second-block .textblock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2em;
    padding: 100px;
}

.about-us img {
    width: 500px;
    object-fit: cover;
    object-position: left center;
}

.about {
    width: auto;
    height: 100%;
    display: flex;
    place-content: center;
    place-items: center;
    padding: 100px;
    background-color: var(--white2);
}

.about p {
    height: fit-content;
    font-size: 2em;
    padding: 50px;
    --webkit-text-wrap: pretty;
    --moz-text-wrap: stable;
    text-align: center;
    will-change: opacity, transform;
    border-bottom: 3px solid var(--black);
    border-top: 3px solid var(--black);
}

span.important {
    font-weight: 600;
}

span.italic {
    font-style: italic;
}

.menu {
    width: auto;
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
    margin: 50px 0;
}

.menu h1 {
    font-size: 2em;
    padding-bottom: 50px;
    text-align: center;
}

.menu__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: min-content;
}

.menu__grid__hidden {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: min-content;
}

.cocktail__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.cocktail__card p {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 25px;
    font-size: 2em;
}

.cocktail__card button {
    display: flex;
    padding: 10px 20px;
    color: var(--black);
    background-color: transparent;
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer;
    text-decoration: underline;
    -webkit-text-decoration-color: var(--black);
    text-decoration-color: var(--black);
    border: none;

}

.cocktail__card button:hover {
    text-decoration: var(--black) underline;
    color: var(--light-grey);
}

.cocktail__card p {
    display: block;
}

.services {
    background-color: var(--white2);
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
    padding: 40px 0;
}

.reasons {
    display: flex;
    overflow: hidden;
    justify-content: space-evenly;
    filter: drop-shadow(10px 10px 10px rgba(0,0,0,0.2));
}

.reasons div {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

}

.reasons button {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 100%;
    font-size: 1.5em;
    text-align: center;
    border: 1px var(--light-grey) solid;
    border-radius: 40px;
}

.reasons button:hover p {
    color: var(--black);
}

.reasons p.reason_text {
    width: 100%;
    display: flex;
    align-content: center;
    place-items: center;
    justify-content: center;
}

.info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
    font-size: 1.5em;
    padding-top: 10px;
}

.info-container p {
    width: 100%;
    text-align: center;
}

.info-container .card {
    display: flex;
    flex-direction: row;
    place-content: center;
    place-items: center;
    width: 70%;
    justify-content: space-around;
    padding: 10px 0 0;
}

.card img {
    width: 300px;
    height: auto;
    object-fit: cover;
}

.card .card--info {
    flex: 1;
}

.info-container .card--name {
    font-weight: bold;
    padding: 20px;
}

.history {
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
}

.history .first-block {
    display: flex;
    height: 900px;
}

.history .first-block img {
    width: 800px;
    object-fit: cover;
}

.history .first-block .history-text-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2em;
}

.history-text-block p {
    text-align: center;
    --webkit-text-wrap: pretty;
    --moz-text-wrap: stable;
    padding: 80px;
}

.history .third-block {
    display: flex;
}

.history .third-block p {
    display: flex;
    text-align: center;
    --webkit-text-wrap: pretty;
    --moz-text-wrap: stable;
    padding: 80px;
    justify-content: center;
    align-items: center;
    font-size: 2em;
}

.history .third-block img {
    width: 600px;
    height: 100%;
    object-fit: cover;
    padding: 20px 20px 20px 40px;
}

.contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
    background-color: var(--white2);
    padding-top: 30px;
}

.contacts {
    display: flex;
    flex-direction: row;
    place-content: center;
    place-items: center;
    text-align: center;
}


.contacts a, .contacts p {
    color: var(--black);
    display: flex;
    place-content: center;
    place-items: center;
    text-align: center;
    padding: 5px;
    text-decoration: none;
}

.contacts a:hover {
    color: var(--background-white);
}

.contact a:visited {
    color: var(--text-white);
}

.contacts > div {
    padding: 50px;
}

.cocktail__carousel{
    width: 100%;
    min-height: 800px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.carousel__button.prev, .carousel__button.next{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    width: min-content;
    padding: 10% 40px;
    background-color: var(--white2);
}


@media (max-width: 850px) {
    .about-us {
        padding: 0;
    }

    .about-us .first-block {
        padding: 40px;
    }

    .about-us .first-block p {
        font-size: 0.7em;
        padding: 20px;
    }

    .about-us .second-block {
        padding: 0;
    }

    .about-us .second-block .textblock {
        height: fit-content;
        padding: 40px;
        font-size: 1.5em;
    }

    .about-us img {
        display: none;
    }

    .menu__grid {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(9, 1fr);
    }

    .menu__grid__hidden {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 1fr);
    }

    .reasons {
        flex-direction: column;
        padding: 0;
        width: 100%;

    }

    .reasons .first, .reasons .second, .reasons .third {
        padding: 20px 10px;
        width: 100%;
    }

    .reasons div{
        padding: 0;
        width: auto;
    }

    .reasons button {
        font-size: 1em;
    }

    .reasons .info-container img {
        width: 150px;
    }

    .second .info-container {
        flex-direction: column;
        padding-top: 20px;
    }

    .info-container .card{
        width: 100%;
        flex-direction: column;
    }

    .info-container .card--name{
        padding: 0;
    }

    .card--info {
        font-size: 0.7em;
    }

    .history h1 {
        padding-top: 50px;
        font-size: 1em;
    }

    .history .first-block {
        height: auto;
    }

    .history .first-block p {
        font-size: 0.7em;
        padding: 40px;
    }

    .history .third-block {
        height: fit-content;
    }

    .history .third-block p {
        font-size: 1.5em;
        padding: 40px;
    }

    .about {
        padding: 50px;
    }

    .about p {
        font-size: 1em;
        padding: 20px;
    }

    .history .first-block img {
        display: none;
    }

    .history .third-block {
        flex-direction: column;
        place-items: center;
    }

    .history .third-block img {
        width: 300px;
        height: 100%;
        padding: 40px 0 0 0;
    }

    .cocktail__card p {
        font-size: 1em;
    }

    .cocktail__card button {
        font-size: 1.1em;
    }

    .contact .contacts {
        flex-direction: column;
    }

    .reasons .cocktail__carousel{
        flex-direction: row;
        width: 100%;
        min-height: 700px;
    }

    .carousel__button.prev, .carousel__button.next{
        width: min-content;
        height: 100%;
        background-color: transparent;
    }

    .carousel__button.prev{
        padding: 100% 0 100% 20px;
    }

    .carousel__button.next{
        padding: 70% 20px 70% 0;
    }

}
