
:root {
    --black: #212529;
    --dark-grey: #343a40;
    --light-grey: #495057;
    --text-white: #f8f9fa;
    --background-white: #e9ecef;
    --white2: #d7d7d7;
    --hover-text: #adb5bd;
    --hover-black: #6c757d;
    --snowy-white: #f8f9fa;
}

.App {
    display: flex;
    flex-direction: column;
    background-color: var(--background-white);
    color: var(--black);
}

header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
}

.video {
    position: relative;
    z-index: 0;
    top: 0;
    width: 100%;
}

.video img {
    position: relative;
    width: 100%;
    height: 100svh;
    object-fit: cover;
}

.video::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, transparent 60%);
}

.video h1 {
    position: absolute;
    bottom: 0;
    padding: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4em;
    font-weight: 500;
    text-align: center;
    color: var(--text-white);
    z-index: 1;
}

.ageRestrict {
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(20px);
    z-index: 3;
    height: 100vh;
    width: 100%;
    display: flex;
    place-content: center;
    align-items: center;
}

.form {
    width: 600px;
    background-color: var(--white2);
    padding: 40px;
    border-radius: 5px;
    text-align: center;
    font-size: 1em;
}

.form p {
    margin-bottom: 5px;
}

.form button {
    background-color: rgb(131, 0, 0);
    color: var(--text-white);
    border: 1px solid var(--black);
    border-radius: 10px;
    padding: 10px 20px;
    margin: 5px;
    font-size: 1em;
    text-shadow: none;
}

.form button:hover {
    background-color: rgb(178, 73, 73);
}


@media screen and (max-width: 850px) {

    .video h1 {
        font-size: 2em;
    }

    .form {
        margin: 0 20px;
        padding: 30px;
        font-size: 0.9em;
    }

    .form button {
        font-size: 0.8em;
    }
}

