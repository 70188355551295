.image img, .showcase img {
    width: 500px;
    height: 100%;
    object-fit: cover;
    object-position: 50% 90%;
}

.image, .showcase {
    height: 700px;
    width: 500px;
    position: relative;
}

.showcase-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    min-height: 200px;
}

.showcase-text p{
    font-size: 2em;
    font-weight: bold;
}

.showcase-text ul{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.25em;
}

.showcase-text li{
    list-style-type: none;
    margin-top: 5px;
}


.image::before {
    will-change: background;
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, transparent 60%);
    transition: all 0.2s ease-in-out;
}

.image h2 {
    font-size: 2em;
    padding-bottom: 20px;
    display: flex;
    font-weight: bold;
}

.image .image__description {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    color: var(--text-white);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
}

.image ul {
    display: none;
    flex-direction: column;
    list-style-type: none;


    li{
        display: flex;
        word-break: break-word;
        justify-content: center;
        text-align: center;
        font-size: 1.5em;
        padding: 5px;
    }
}



.image:hover .image__description {
    cursor: pointer;
    justify-content: center;
    ul {
        display: flex;
    }
}

.image:hover::before {
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, transparent 60%),
    rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
    .image, .showcase{
        width: 300px;
        height: 500px;
    }
    .image img, .showcase img {
        width: 300px;
        height: 500px;
    }
    .image h2{
        font-size: 1em;
    }
    .image ul li{
        font-size: 1em;
    }

    .showcase-text{
        min-height: 150px;
    }

    .showcase-text p{
        font-size: 1.5em;
    }

    .showcase-text li{
        font-size: 0.7em;
    }
}

@media (hover: none) {
    .image:focus-visible::before {
        background: rgba(0, 0, 0, 0.7);
    }
}
